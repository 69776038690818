import createClient from "@tt/vue-components/src/services/apiClient";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";

const apiClient = createClient(EnvProvider.value("INSTORE_API_URL"));

const InStorePaymentsService = {
  locations: {
    ...baseApiService(apiClient, 'locations'),
  },
  sessions: {
    ...baseApiService(apiClient, 'sessions')
  },
  paymentRequestItems: {
    ...baseApiService(apiClient, 'payment_request_items')
  },
  paymentRequests: {
    ...baseApiService(apiClient, 'payment_requests'),
    sendPostback: async (task) => {
      return await apiClient.post("payment_requests:execute_postback", task)
    },
  }
}
export default InStorePaymentsService;
