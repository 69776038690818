import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters,
} from "@tt/vue-components";
import InStorePaymentsService from "@/services/InStorePaymentsService.js";

const postbackLog = {
  namespaced: true,
  state: {
    ...tableState,
    pagination: { itemsPerPage: 20, itemsPage: 1 },
    footerProps: { "items-per-page-options": [10, 20, 40, 80] },
    request: null,
  },
  mutations: {
    ...tableMutations,
    SET_REQUEST(state, request) {
      state.request = request;
    },
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search,
        };
        InStorePaymentsService.paymentRequests
          .getSubResource(state.request.id, 'postback_logs', params)
          .then((json) => {
            commit("SET_ITEMS", json);
            commit("SET_LOADING", false);
          })
          .catch((err) => {
            commit("SET_LOADING", false);
            console.log(err);
          });
      }
    },
    setRequest({ commit }, item) {
      commit("SET_ITEM", item);
      commit("SET_REQUEST", item);
    },
  },
  getters: {
    ...tableGetters,
  },
};

export default postbackLog;
