import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters,
} from "@tt/vue-components";
import InStorePaymentsService from "@/services/InStorePaymentsService.js";
import customerNameFilter from "@/store/modules/requestsOverview/filters/CustomerNameFilter.js";
import customerReferenceFilter from "@/store/modules/requestsOverview/filters/CustomerReferenceFilter.js";
import orderReferenceFilter from "@/store/modules/requestsOverview/filters/OrderReferenceFilter.js";
import statusFilter from "@/store/modules/requestsOverview/filters/StatusFilter.js";
import locationsFilter from "@/store/modules/requestsOverview/filters/locationsFilter.js";
import postbackLog from "@/store/modules/requestsOverview/postbackLog.js";

const requestsOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    locationsFilter,
    customerReferenceFilter,
    customerNameFilter,
    orderReferenceFilter,
    statusFilter,
    postbackLog
  },
  state: {
    ...tableState,
    pagination: { itemsPerPage: 20, itemsPage: 1 },
    footerProps: { "items-per-page-options": [10, 20, 40, 80] },
    request: null,
  },
  mutations: {
    ...tableMutations,
    SET_REQUEST(state, request) {
      state.request = request;
    },
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search,
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InStorePaymentsService.paymentRequests
          .list(params, { signal: this.requestController.signal })
          .then((json) => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    setRequest({ commit, dispatch }, item) {
      commit("SET_ITEM", item);
      commit("SET_REQUEST", item);
      dispatch("postbackLog/setRequest", item);
      if (item !== null) {
        dispatch("postbackLog/fetchItems", true);
      }
    },
    edit({ commit }, request) {
      return new Promise((resolve, reject) => {
        InStorePaymentsService.paymentRequests
          .update(request.id, request)
          .then(() => {
            commit("SET_REQUEST", request);
            commit("SET_ITEM", request);
            resolve();
          })
          .catch((err) => {
            reject(err["hydra:description"]);
          });
      });
    },
    executePostback(_, request) {
      return new Promise((resolve, reject) => {
        const task = {
          "paymentRequestId": request.id
        }
        InStorePaymentsService.paymentRequests.sendPostback(task)
            .then(() => {
              resolve();
            })
            .catch(err => {
              let message = ""
              if (err["hydra:description"]) {
                message = err["hydra:description"]
              } else if (err["detail"]) {
                message = err["detail"]
              }

              reject(message);
            });
      });
    }
  },
  getters: {
    ...tableGetters,
  },
};

export default requestsOverview;
