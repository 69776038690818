import Vue from "vue";
import Vuex from "vuex";
import locationsOverview from "@/store/modules/locationsOverview.js";
import requestsOverview from "@/store/modules/requestsOverview.js";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    locationsOverview,
    requestsOverview,
  },
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : [],
  getters: {}
});

export default store;