import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters,
} from "@tt/vue-components";
import nameFilter from "@/store/modules/locationsOverview/filters/nameFilter.js";
import locationCodeFilter from "@/store/modules/locationsOverview/filters/locationCodeFilter.js";
import isActiveFilter from "@/store/modules/locationsOverview/filters/isActiveFilter.js";
import referenceFilter from "@/store/modules/locationsOverview/filters/referenceFilter.js";
import InStorePaymentsService from "@/services/InStorePaymentsService.js";

const locationOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    nameFilter,
    locationCodeFilter,
    isActiveFilter,
    referenceFilter
  },
  state: {
    ...tableState,
    pagination: { itemsPerPage: 20, itemsPage: 1 },
    footerProps: { "items-per-page-options": [10, 20, 40, 80] },
    location: null,
    selection: []
  },
  mutations: {
    ...tableMutations,
    SET_LOCATION(state, location) {
      state.location = location;
    },
    SET_SELECTION(state, data) {
      state.selection = data
    }
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search,
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InStorePaymentsService.locations
          .list(params, { signal: this.requestController.signal })
          .then((json) => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    setSelection({commit}, data) {
      commit("SET_SELECTION", data)
    },
    selectLocation({ commit }, item) {
      commit("SET_ITEM", item);
      commit("SET_LOCATION", item);
    },
    edit({ commit }, location) {
      return new Promise((resolve, reject) => {
        InStorePaymentsService.locations.update(location.code, location)
          .then((data) => {
            commit("EDIT_ITEM", data);
            commit("SET_LOCATION", data);
            commit("SET_ITEM", data);
            resolve();
          })
          .catch((err) => {
            reject(err["hydra:description"]);
          });
      });
    },
    add({ commit }, location) {
      return new Promise((resolve, reject) => {
        InStorePaymentsService.locations
          .create(location)
          .then((data) => {
            commit("SET_LOCATION", data);
            commit("ADD_ITEM", data);
            resolve();
          })
          .catch((err) => {
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters,
    getLocationById: (state) => (id) => {
      return state.items.find((item) => item.id === id);
    },
  },
};

export default locationOverview;
